<template>
  <section>
    <div v-if="currentImage">
      <p class="text-center">Archivo cargado correctamente.</p>
      <img
        class="w-full h-48 pb-3 pt-6 px-2 rounded-lg object-cover"
        :src="currentImage"
        alt="Current image"
      />
    </div>
    <div v-if="!currentImage">
      <input
        type="file"
        ref="fileInput"
        class="hidden"
        accept="image/*"
        @change="handleClick"
      />
      <div
        @click="$refs.fileInput.click()"
        @drop="handleDrop"
        @dragover="handleDragover"
        @dragenter="handleDragover"
        class="px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl"
      >
        <feather-icon
          icon="UploadCloudIcon"
          svgClasses="h-16 w-16 stroke-current text-grey"
          class="block"
        />
        <span>Arrastre el archivo o </span>
        <span
          class="font-medium text-primary"
          @click.stop="$refs.fileInput.click()"
          >Busque</span
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    currentImage: {
      type: String,
      default: null,
    },
  },
  methods: {
    _uploadFile(rawFile) {
      this.$refs['fileInput'].value = null;
      const reader = new FileReader();
      reader.onloadend = () => {
        this.onSuccess(reader.result);
      };
      reader.readAsDataURL(rawFile);
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Error de validación',
          text: 'Solo se subir un solo archivo.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });
        return;
      }
      const rawFile = files[0];
      this._uploadFile(rawFile);
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0];
      if (!rawFile) return;
      this._uploadFile(rawFile);
    },
  },
};
</script>
