<template>
  <div>
    <vs-prompt
      :active.sync="toggleEditInvoiceImagePrompt"
      class="modal"
      title="Cargar foto"
      @accept="importImage"
      @cancel="resetImagePrompt"
      accept-text="Cargar"
      cancel-text="Descartar"
    >
      <div>
        <h1 class="font-semibold text-lg mb-4">Sube un la imagen que desea</h1>
        <UploadImageModal
          :currentImage="currentImage"
          :onSuccess="onUploadImage"
        />
      </div>
    </vs-prompt>

    <ValidationObserver ref="observerStatus" v-slot="{ invalid }">
      <vs-prompt
        :active.sync="payInvoicePromptIsVisible"
        title="Pagar factura"
        @accept="payInvoice(invoiceToPay)"
        accept-text="Pagar"
        :is-valid="!invalid"
        cancel-text="Descartar"
        v-if="invoiceToPay"
      >
        <div v-if="accountsLoading">
          <p class="my-3 text-grey text-center">Cargando...</p>
        </div>
        <div v-else>
          <vs-select
            class="w-full"
            label="Cuenta de ingreso a caja:"
            v-model="payInvoiceInput.income_account_id"
          >
            <vs-select-item
              :key="index"
              :value="account.id"
              :text="account.name"
              v-for="(account, index) in accounts"
            />
          </vs-select>
          <vs-select
            class="w-full"
            label="Cuenta de retención:"
            v-model="payInvoiceInput.bank_withholding_account_id"
          >
            <vs-select-item
              :key="index"
              :value="account.id"
              :text="account.name"
              v-for="(account, index) in accounts"
            />
          </vs-select>

          <ValidationProvider
            name="Ingreso a caja"
            v-slot="{ errors }"
            rules="min_value:0"
          >
            <vs-input
              v-model="payInvoiceInput.income"
              label="Ingreso a caja"
              class="w-full mt-3"
              type="number"
            />
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="Retención"
            v-slot="{ errors }"
            rules="min_value:0"
          >
            <vs-input
              v-model="payInvoiceInput.bank_withholding"
              label="Retención"
              class="w-full mt-3"
              type="number"
            />
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </vs-prompt>
    </ValidationObserver>

    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4"
    >
      <vx-card class="mb-4 col-span-4" title="Filtros">
        <div class="flex flex-wrap lg:flex-no-wrap gap-3 items-end">
          <vs-tabs class="status-tabs" alignment="fixed">
            <vs-tab
              v-for="state in invoiceStatuses"
              v-bind:key="state.id"
              :label="state.name"
              @click="invoiceStatusSelected = state.id"
            >
            </vs-tab>
          </vs-tabs>
        </div>
        <div class="flex justify-between items-center gap-3">
          <div class="w-full">
            <label class="text-sm text-san-juan font-bold">Buscar</label>
            <vs-input
              class="mb-4 w-full md:mb-0 mr-4"
              v-model="searchQuery"
              placeholder="Buscar por texto"
            />
          </div>
          <div class="flex gap-3">
            <vs-select
              label="Mes"
              class="w-full"
              v-model="selectedMonth"
              placeholder="Seleccione un mes"
              autocomplete
            >
              <vs-select-item
                :key="index"
                :value="index + 1"
                :text="item"
                v-for="(item, index) in availableMonths"
              />
            </vs-select>
            <vs-select
              label="Año"
              class="w-full"
              v-model="selectedYear"
              placeholder="Seleccione un año"
              autocomplete
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in availableYears"
              />
            </vs-select>
            <vs-select
              class="w-full"
              label="Remitentes"
              multiple
              v-model="sendersSelected"
            >
              <vs-select-item
                :key="index"
                :value="item.id"
                :text="item.name"
                v-for="(item, index) in senders"
              />
            </vs-select>
            <div class="mt-6">
              <vs-button
                class="mb-4 md:mb-0 mr-3 whitespace-no-wrap"
                :to="{ name: 'finanzas-facturacion-crear' }"
              >
                Crear factura
              </vs-button>
            </div>
          </div>
        </div>
      </vx-card>
    </div>

    <div
      v-if="loadingTotalInvoices"
      class="mt-6 transition-all duration-200 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-1 md:gap-4"
    >
      <div
        v-for="(item, index) in [1, 2, 3, 4, 5]"
        :key="index"
        class="h-24 w-[10rem] rounded-lg border-grey-light bg-opacity-25 mb-8 shadow-md"
      ></div>
    </div>

    <div
      v-if="!loadingTotalInvoices"
      class="mt-6 mb-6 transition-all duration-200 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-1 md:gap-4"
    >
      <statistics-card-line
        hideChart
        class=""
        icon="ArrowUpIcon"
        icon-right
        :statistic="totalInvoices.credit_services | currencyFormat"
        statisticTitle="Servicios a crédito"
        color="success"
      />
      <statistics-card-line
        hideChart
        class=""
        icon="ArrowUpIcon"
        icon-right
        :statistic="totalInvoices.invoiced | currencyFormat"
        statisticTitle="Facturado"
        color="success"
      />
      <statistics-card-line
        hideChart
        class=""
        icon="MinusIcon"
        icon-right
        :statistic="
          (totalInvoices.credit_services - totalInvoices.invoiced)
            | currencyFormat
        "
        statisticTitle="Faltante (Servicios - Facturado)"
        color="warning"
      />
      <statistics-card-line
        hideChart
        class=""
        icon="ArrowUpIcon"
        icon-right
        :statistic="totalInvoices.income | currencyFormat"
        statisticTitle="Ingreso a caja"
        color="success"
      />
      <statistics-card-line
        hideChart
        class=""
        icon="ArrowDownIcon"
        icon-right
        :statistic="totalInvoices.bank_withholding | currencyFormat"
        statisticTitle="Retención"
        color="danger"
      />
    </div>

    <vx-card>
      <div class="w-full" v-if="!loading && !invoicesLoading">
        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="agGridState.gridOptions"
          class="ag-theme-material w-100 tw-w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="agGridState.defaultColDef"
          :rowData="invoices"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
          :overlayLoadingTemplate="overlayLoadingTemplate"
          @grid-ready="onGridReady"
        >
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="agGridState.maxPageNumbers"
          v-model="currentPage"
        />
      </div>
      <div v-else class="h-64 my-24 flex justify-center items-center">
        <p class="text-grey">Cargando...</p>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { useAgGrid } from '@/composable/useAgGrid.js';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';

import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/src/locale';

import CellRendererAmount from '@/components/cell-renderer/CellRendererAmount.vue';
import CellRendererPercentAmount from '@/components/cell-renderer/CellRendererPercentAmount.vue';
import UploadImageModal from '@/components/socio/modals/UploadImageModal.vue';
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
import CellRendererInvoiceStatus from './cell-renderer/CellRendererInvoiceStatus.vue';
import CellRendererCancelInvoice from './cell-renderer/CellRendererCancelInvoice.vue';
import CellRendererPayInvoice from './cell-renderer/CellRendererPayInvoice.vue';
import CellRendererImage from './cell-renderer/CellRendererImage.vue';
import CellRendererEditImage from './cell-renderer/CellRendererEditImage.vue';

import {
  INVOICES,
  CONTACTS,
  TOTAL_INVOICES,
  ACCOUNTS,
} from '@/graphql/queries.js';
import {
  CANCEL_INVOICE,
  PAY_INVOICE,
  UPDATE_INVOICE_IMAGE,
} from '@/graphql/mutations';

export default defineComponent({
  components: {
    CellRendererAmount,
    CellRendererPercentAmount,
    UploadImageModal,
    Datepicker,
    StatisticsCardLine,
    CellRendererInvoiceStatus,
    CellRendererCancelInvoice,
    CellRendererPayInvoice,
    CellRendererImage,
    CellRendererEditImage,
  },
  setup(_, { root }) {
    const state = reactive({
      columnDefs: [
        {
          headerName: 'Ref',
          field: 'id',
          width: 150,
          filter: true,
        },
        {
          headerName: 'Estado',
          field: 'status',
          width: 200,
          filter: true,
          cellRendererFramework: 'CellRendererInvoiceStatus',
        },
        {
          headerName: 'Observación',
          field: 'observation',
          width: 240,
          filter: true,
        },
        {
          headerName: 'Fec. Inicio',
          field: 'start_date',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Fec. Fin',
          field: 'end_date',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Val. Facturado',
          field: 'credit_value',
          width: 230,
          filter: true,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Val. Ingreso',
          field: 'income',
          width: 230,
          filter: true,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Val. Retención',
          field: 'bank_withholding',
          width: 230,
          filter: true,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Fec. Pago',
          field: 'paid_at',
          width: 300,
          filter: true,
        },
        {
          headerName: 'Fec. Cancelación',
          field: 'cancelled_at',
          width: 300,
          filter: true,
        },
        {
          headerName: 'Fec. Creación',
          field: 'created_at',
          width: 300,
          filter: true,
        },
        {
          headerName: 'Pagar',
          field: 'status',
          width: 240,
          filter: true,
          cellRendererFramework: 'CellRendererPayInvoice',
        },
        {
          headerName: 'Adjunto',
          field: 'image',
          width: 150,
          filter: true,
          cellRendererFramework: 'CellRendererImage',
        },
        {
          headerName: 'Editar',
          field: 'image',
          width: 150,
          filter: true,
          cellRendererFramework: 'CellRendererEditImage',
        },
        {
          headerName: 'Cancelar',
          field: 'status',
          width: 200,
          filter: true,
          cellRendererFramework: 'CellRendererCancelInvoice',
        },
      ],
      loading: false,
      overlayLoadingTemplate:
        '<span>Please wait while your rows are loading</span>',
      invoiceStatusSelected: null,
      invoiceStatuses: [
        {
          id: null,
          name: 'Todos',
        },
        {
          id: 'PAID',
          name: 'Pagados',
        },
        {
          id: 'PENDING',
          name: 'Pendientes',
        },
        {
          id: 'CANCELLED',
          name: 'Cancelados',
        },
      ],
      sendersSelected: [],
      currentYear: new Date().getFullYear(),
      selectedYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth() + 1,
      selectedMonth: new Date().getMonth() + 1,

      loadingTotalInvoices: true,

      invoiceToCancel: null,
      invoiceToPay: null,
      payInvoicePromptIsVisible: false,

      payInvoiceInput: {
        income: 0,
        bank_withholding: 0,
        income_account_id: null,
        bank_withholding_account_id: null,
      },
      accountsLoading: false,

      currentImage: null,
      toggleEditInvoiceImagePrompt: false,
      invoiceToChangeImage: null,
    });

    const currentPage = ref(1);
    const first = ref(20);
    const searchQuery = ref('');
    const paginationPageSize = ref('');

    const year = computed(() => state.selectedYear);
    const month = computed(() => state.selectedMonth);
    const statusSelected = computed(() => state.invoiceStatusSelected);
    const sendersSelected = computed(() => state.sendersSelected);

    const availableYears = computed(() => {
      const startYear = 2020;
      const endYear = state.currentYear;
      const years = [];
      for (let year = startYear; year <= endYear; year++) {
        years.push(year.toString());
      }
      return years;
    });
    const availableMonths = computed(() => {
      const monthNames = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ];
      return monthNames;
    });

    const {
      result: invoicesResult,
      loading: invoicesLoading,
      refetch: invoicesRefetch,
    } = useQuery(INVOICES, {
      page: currentPage,
      first: first,
      search: searchQuery,
      year: year,
      month: month,
      senders: sendersSelected,
      status: statusSelected,
    });
    const invoices = useResult(
      invoicesResult,
      [],
      (data) => data.invoices.data
    );
    watch(invoicesLoading, (value) => {
      state.loading = value;
    });

    const {
      result: totalInvoicesResult,
      loading: totalInvoicesLoading,
      refetch: totalInvoicesRefetch,
    } = useQuery(TOTAL_INVOICES, {
      search: searchQuery,
      year: year,
      month: month,
      senders: sendersSelected,
      status: statusSelected,
    });
    const totalInvoices = useResult(
      totalInvoicesResult,
      { total: 0 },
      (data) => {
        return data.totalInvoices;
      }
    );
    watch(totalInvoicesLoading, (value) => {
      state.loadingTotalInvoices = value;
    });

    const { result: sendersResult, loading: sendersLoading } = useQuery(
      CONTACTS,
      {},
      {
        fetchPolicy: 'network-only',
      }
    );
    const senders = useResult(sendersResult, [], (data) => data.allContacts);
    watch(sendersLoading, (value) => {
      state.loading = value;
    });

    const paginatorInfo = useResult(invoicesResult, [], (data) => ({
      total: data.invoices.paginatorInfo.total,
      perPage: data.invoices.paginatorInfo.perPage,
    }));
    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );

    const { result: accountsResult, loading: accountsLoading } =
      useQuery(ACCOUNTS);
    const accounts = useResult(accountsResult, [], (data) => data.accounts);
    watch(accountsLoading, (value) => {
      state.accountsLoading = value;
    });

    const { mutate: cancelInvoiceMutation, loading: cancelInvoiceLoading } =
      useMutation(CANCEL_INVOICE);
    watch(cancelInvoiceLoading, (value) => {
      state.loading = value;
    });

    const { mutate: payInvoiceMutation, loading: payInvoiceLoading } =
      useMutation(PAY_INVOICE);
    watch(payInvoiceLoading, (value) => {
      state.loading = value;
    });

    const cancelInvoice = async () => {
      if (!state.invoiceToCancel) return;

      try {
        await cancelInvoiceMutation({ id: state.invoiceToCancel });
        root.$vs.notify({
          color: 'success',
          title: `Se ha cancelado la factura.`,
          text: `La fatura se ha cancelado correctamente.`,
        });

        invoicesRefetch();
        totalInvoicesRefetch();
      } catch (error) {
        console.log({ error });
      }
    };

    const onCancelInvoice = ({ invoice }) => {
      state.invoiceToCancel = invoice.id;
      root.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirmar cancelación de factura`,
        text: `¿Seguro que quieres cancelar la factura?`,
        accept: cancelInvoice,
        acceptText: 'Cancelar factura',
        cancelText: 'Descartar',
      });
    };

    const onPayInvoice = ({ invoice }) => {
      if (invoice.status === 'PAID') {
        root.$vs.notify({
          color: 'warning',
          title: `Factura pagada.`,
          text: `La factura ya ha sido pagada, no es necesario hacerlo nuevamente.`,
        });
        return;
      }
      if (invoice.status === 'CANCELLED') {
        root.$vs.notify({
          color: 'warning',
          title: `Factura cancelada.`,
          text: `La factura ya ha sido cancelada, no es posible hacer el pago de esta.`,
        });
        return;
      }
      state.invoiceToPay = invoice;
      state.payInvoicePromptIsVisible = true;
    };

    const payInvoice = async (invoice) => {
      const totalToPay =
        +state.payInvoiceInput.income + +state.payInvoiceInput.bank_withholding;
      if (totalToPay !== invoice.credit_value) {
        root.$vs.notify({
          color: 'warning',
          title: `Valor ingresado insuficiente.`,
          text: `La factura debe pagarse al completo. La suma de valor en caja y retención debe ser igual al saldo de la factura. Valor a pagar: $${invoice.credit_value}. Valor Pagado: $${totalToPay}`,
        });
        return;
      }

      if (
        state.payInvoiceInput.income_account_id === null ||
        state.payInvoiceInput.bank_withholding_account_id === null
      ) {
        root.$vs.notify({
          color: 'warning',
          title: `Los campos de cuentas son obligatorios.`,
          text: `Debe seleccionar las cuentas destino de las transacciones.`,
        });
        return;
      }

      try {
        await payInvoiceMutation({
          input: {
            id: invoice.id,
            income: state.payInvoiceInput.income,
            bank_withholding: state.payInvoiceInput.bank_withholding,
            income_account_id: state.payInvoiceInput.income_account_id,
            bank_withholding_account_id:
              state.payInvoiceInput.bank_withholding_account_id,
          },
        });
        root.$vs.notify({
          color: 'success',
          title: `Se ha pagado la factura.`,
          text: `La fatura se ha pagado correctamente.`,
        });
        state.payInvoiceInput = {
          income: 0,
          bank_withholding: 0,
          income_account_id: null,
          bank_withholding_account_id: null,
        };

        invoicesRefetch();
        totalInvoicesRefetch();
      } catch (error) {
        console.log({ error });
      }
    };

    const editImage = ({ invoice }) => {
      state.toggleEditInvoiceImagePrompt = true;
      state.currentImage = null;
      state.invoiceToChangeImage = invoice;
    };

    const onUploadImage = (image) => {
      state.currentImage = image;
    };
    const importImage = async () => {
      if (!state.currentImage) {
        root.$vs.notify({
          color: 'warning',
          title: 'Imagen obligatoria',
          text: 'Debe haber cargado una imagen.',
        });
        return;
      }
      try {
        state.loading = true;
        await root.$apollo.mutate({
          mutation: UPDATE_INVOICE_IMAGE,
          variables: {
            input: {
              id: state.invoiceToChangeImage.id,
              image: state.currentImage,
            },
          },
        });

        root.$vs.notify({
          color: 'success',
          title: 'Correctamente Actualizado',
          text: 'Se actualizó la factura.',
        });
        invoicesRefetch();
        state.loading = false;
      } catch (error) {
        state.loading = false;
        console.log(error);
      }
    };
    const resetImagePrompt = () => {
      state.currentImage = null;
    };

    const { state: agGridState, onGridReady } = useAgGrid({ root });

    return {
      ...toRefs(state),
      availableYears,
      availableMonths,
      invoicesLoading,
      paginationPageSize,
      agGridState,
      totalPages,
      currentPage,
      invoices,
      searchQuery,
      langEs: es,
      onGridReady,
      totalInvoicesRefetch,
      invoicesRefetch,
      totalInvoices,
      totalInvoicesLoading,
      senders,
      accounts,
      cancelInvoice,
      onCancelInvoice,
      onPayInvoice,
      payInvoice,
      onUploadImage,
      importImage,
      resetImagePrompt,
      editImage,
    };
  },
  created() {
    this.agGridState.gridOptions.context = {
      componentParent: this,
    };
  },
});
</script>

<style lang="scss">
.status-tabs {
  // VTab Active
  .vs-tabs--li.activeChild {
    background-color: #00d0eb;
    color: white !important;
  }
  .vs-tabs--li.activeChild > button > span {
    color: white;
  }
  // All VTabs
  .con-ul-tabs > ul > li {
    background-color: #2d4e6010;
    color: #2d4e60;
  }
  // Delete Line bottom
  .con-ul-tabs > span {
    display: none;
  }
}
</style>
