<template>
  <div>
    <feather-icon
      v-if="params.value"
      icon="ImageIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="viewImage"
    />
    <feather-icon
      v-else
      icon="AlertTriangleIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-nepal cursor-pointer"
    />
  </div>
</template>

<script>
export default {
  name: 'CellRendererImage',
  methods: {
    viewImage() {
      window.open(this.params.value);
    },
  },
};
</script>
