<template>
  <div>
    <feather-icon
      @click="editImage"
      icon="EditIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
    />
  </div>
</template>

<script>
export default {
  name: 'CellRendererEditImage',
  methods: {
    editImage() {
      this.params.context.componentParent.editImage({
        invoice: this.params.data,
      });
    },
  },
};
</script>
