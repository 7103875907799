<template>
  <div>
    <feather-icon
      @click="cancelInvoice"
      icon="TrashIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer"
    />
  </div>
</template>

<script>
export default {
  name: 'CellRendererCancelInvoice',
  methods: {
    cancelInvoice() {
      this.params.context.componentParent.onCancelInvoice({
        invoice: this.params.data,
      });
    },
  },
};
</script>
